// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./footer.css"
// import avatar from "../assets/images/profile.jpg"
import { FaGithub, FaLinkedin, FaEnvelope,FaMedium } from "react-icons/fa"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer className="Footer">
    <div className="Footer__links">
      <a
        className="Footer__links__link"
        href="https://github.com/mostafa-drz"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaGithub />
      </a>
      <a
        className="Footer__links__link"
        href="https://www.linkedin.com/in/mostafa-darehzereshki/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaLinkedin />
      </a>
      <a
        className="Footer__links__link"
        href="mailto:darehzereshki.mostafa@gmail.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaEnvelope />
      </a>
      <a className="Footer__links__link" href="https://darehzereshki-mostafa.medium.com/" target="_blank" rel="noreferrer noopener">
        <FaMedium/>
      </a>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
