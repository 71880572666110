import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostThumbnailBlogPage from "../components/PostThumbnailBlogPage"
import "./blog-list-template.css"

export default class BlogList extends React.Component {
  render() {
    // const posts = this.props.data.allMarkdownRemark.edges
    const list = this.props.data.list.nodes

    return (
      <Layout>
        <div className="BlogListTemplateContainer">
          {list.map(
            ({
              title,
              id,
              slug,
              heroImage,
              description: { description },
              publishDate,
              tags,
            }) => (
              <PostThumbnailBlogPage
                title={title}
                id={id}
                slug={slug}
                description={description}
                image={heroImage ? heroImage.file.url : null}
                publishDate={publishDate}
                tags={tags}
                key={id}
              />
            )
          )}
        </div>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    list: allContentfulBlogPost(limit: $limit, skip: $skip) {
      nodes {
        id
        title
        slug
        tags
        description {
          description
        }
        heroImage {
          file {
            url
          }
        }
        publishDate
      }
    }
  }
`
