import React from "react"
import { Link } from "gatsby"
import "./PostThumbnailBlogPage.css"
import LazyLoad from "react-lazyload"
import ReactMarkdown from "react-markdown"

const PostThumbnailBlogPage = ({
  title,
  id,
  tags,
  image,
  description,
  slug,
  publishDate,
}) => {
  return (
    <div className="PostThumbnailBlogPage">
      <div style={{ display: "flex" }}>
        {image && (
          <LazyLoad>
            <img src={image} alt={title} className="PostThumbnail__image " />
          </LazyLoad>
        )}
        <div>
          <Link className="PostThumbnail__link" to={`/blog/${slug}`}>
            <h2>{title}</h2>
          </Link>
          <ReactMarkdown source={description} />
        </div>
      </div>
      <footer>
        <time dateTime={publishDate}>
          {new Date(publishDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </time>
        <div style={{ display: "flex" }}>
          {tags.map((t, index) => (
            <span className="BlogPost__tag" key={`tags-${t}`}>
              #{t}
            </span>
          ))}
        </div>
      </footer>
    </div>
  )
}

export default PostThumbnailBlogPage
