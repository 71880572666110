import PropTypes from "prop-types"
import React from "react"
import avatar from "../assets/images/profile.jpg"
import "./header.css"
const Header = ({ siteTitle }) => (
  <header className="Header">
    <div className="Header__bio">
      <p>Mostafa Darehzereshki</p>
      <small>Software Engineer - Engineering Manager</small>
    </div>

    {/* <img src={avatar} alt="Mostafa Darehzereshki" className="Header__avatar" /> */}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
